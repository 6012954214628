import React from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';

import {
  AspectRatioWrapper,
  NamedLink,
  OfflineWorkout,
  OnlineWorkout,
  ResponsiveImage,
} from '../../components';

import css from './ListingCard.module.css';
import { listingServiceType } from '../../custom-config';
import {
  findMinMaxPriceRange,
  formatPriceRange,
  formatPriceRangeSingle,
} from '../../containers/ListingPage/ListingPage.shared';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
  return (
    <div className={css.price}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      {isBookable ? (
        <div className={css.perUnit}>
          <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
        </div>
      ) : null}
    </div>
  );
};

const getBookingTypes = publicData => {
  const { servicesList = [] } = publicData || {};
  const bookingTypes = servicesList.reduce((prev, service) => {
    const oneToOneCheckBox = service?.oneToOneCheckBox;
    const groupCheckbox = service?.groupCheckbox;
    if (oneToOneCheckBox && Array.isArray(oneToOneCheckBox) && oneToOneCheckBox.length > 0) {
      prev['1:1'] = '1:1';
    }
    if (groupCheckbox && Array.isArray(groupCheckbox) && groupCheckbox.length > 0) {
      prev['group'] = 'group';
    }
    return prev;
  }, {});
  return Object.keys(bookingTypes);
};

const getServiceTypes = publicData => {
  const { servicesList = [] } = publicData || {};
  const bookingTypes = servicesList.reduce((prev, service) => {
    const offlineCheckBox = service?.offlineCheckBox;
    const onlineCheckBox = service?.onlineCheckBox;
    if (offlineCheckBox && Array.isArray(offlineCheckBox) && offlineCheckBox.length > 0) {
      prev[listingServiceType.offline] = listingServiceType.offline;
    }
    if (onlineCheckBox && Array.isArray(onlineCheckBox) && onlineCheckBox.length > 0) {
      prev[listingServiceType.online] = listingServiceType.online;
    }
    return prev;
  }, {});
  return Object.keys(bookingTypes);
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  const bookingType = getBookingTypes(currentListing.attributes?.publicData);
  const serviceType = getServiceTypes(currentListing.attributes?.publicData);
  const { servicesList = [] } = currentListing.attributes?.publicData;
  const priceArray =
    servicesList?.length > 1
      ? servicesList?.map(m => {
          return JSON.parse(m?.servicePrice);
        })
      : price.amount;
  const { min, max } = priceArray.length > 1 && findMinMaxPriceRange(priceArray);
  const priceRange =
    min !== undefined && max !== undefined
      ? formatPriceRange(min, max)
      : formatPriceRangeSingle(priceArray);
  const imageOrVideo = (currentListing.attributes?.publicData?.uploadVedio || [])[0];
  const srcLink = imageOrVideo && imageOrVideo.location;
  const srcType = imageOrVideo && imageOrVideo.key;

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <AspectRatioWrapper
        className={css.aspectRatioWrapper}
        width={aspectWidth}
        height={aspectHeight}
        {...setActivePropsMaybe}
      >
        {/* <LazyImage
          rootClassName={css.rootForImage}
          alt={title}
          image={firstImage}
          variants={variants}
          sizes={renderSizes}
        /> */}
        {srcType && srcLink ? (
          srcType.includes('videos') ? (
            <>
              <video className={classNames(css.media, css.blurredMedia)}>
                <source src={srcLink} />
              </video>
              <video className={css.media}>
                <source src={srcLink} />
              </video>
            </>
          ) : (
            <>
              <img
                src={srcLink}
                alt="Preview"
                className={classNames(css.media, css.blurredMedia)}
              />
              <img src={srcLink} alt="Preview" className={css.media} />
            </>
          )
        ) : null}
        <div className={css.userInfo}>
          <span>
            {currentListing.attributes?.publicData?.firstName}{' '}
            {/* {currentListing.attributes?.publicData?.lastName} */}
          </span>
          <span>{currentListing.attributes?.description}</span>
        </div>
      </AspectRatioWrapper>
      <div className={css.info}>
        {/* <PriceMaybe price={price} publicData={publicData} config={config} intl={intl} /> */}
        <div className={css.listingPrice}>{priceRange}</div>

        {bookingType.map(booking => (
          <div className={classNames(css.booking, css.margin)} key={booking}>
            {booking}
          </div>
        ))}
        {serviceType.map(service =>
          service === listingServiceType.offline ? (
            <OfflineWorkout className={css.margin} key={service} />
          ) : service === listingServiceType.online ? (
            <OnlineWorkout className={css.margin} key={service} />
          ) : null
        )}

        {/* <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          {showAuthorInfo ? (
            <div className={css.authorInfo}>
              <FormattedMessage id="ListingCard.author" values={{ authorName }} />
            </div>
          ) : null}
        </div> */}
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
